import React, { useState, useEffect, useCallback } from 'react'
import * as S from './Schedules.style'
import { format, isFuture, isSameDay } from 'date-fns'
import { IWorkingTime } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import SchedulesModal from '../SchedulesModal'
import { useApi } from '@bonliva-traits/api'

const tableColumns = [
  {
    label: 'Period',
  },
  {
    label: 'Dagar',
  },
  {
    label: 'Arbetstid',
  },
  {
    label: 'Paus',
  },
  {
    label: 'Tar samtal',
  },
]

const Schedules: React.FC = () => {
  const api = useApi()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [selected, setSelected] = useState<IWorkingTime>()

  const workingTimes = useApiState<IWorkingTime[]>('/v1/treater/working-times')

  useEffect(() => {
    workingTimes.get()
  }, [showModal])

  const openCreateModalHandler = useCallback(() => {
    setSelected(undefined)
    setShowModal(true)
  }, [])

  const onDeletedHandler = useCallback(async () => {
    if (!selected) return
    await api.delete(`/v1/treater/working-times/${selected.id}`)
    await workingTimes.get()
    setShowDeleteModal(false)
    setShowSuccessModal(true)
  }, [selected])

  return (
    <React.Fragment>
      <Table.Wrapper
        header={
          <Table.Header
            title="Scheman"
            showButton
            buttonTitle="Skapa nytt schema"
            buttonOnClick={openCreateModalHandler}
          />
        }
        head={<Table.Head columns={tableColumns} />}
        body={
          <Table.Body isLoading={workingTimes.isLoading}>
            {workingTimes.hasLoaded && !workingTimes.data?.length && (
              <S.EmptyInbox>
                <td>
                  <Alert type="info" title="Inga scheman hittades" />
                </td>
              </S.EmptyInbox>
            )}

            {workingTimes.data?.map((w) => {
              const mappedDays = w.weekdays
                .map((d) => (d - 1 >= 0 ? d - 1 : 6))
                .sort()

              const startDay = Math.min(...mappedDays)
              const endDay = Math.max(...mappedDays)

              const hasMoreThanOneDay = mappedDays.length > 1

              const isRange = mappedDays.every((d, i) => {
                if (i === 0) return true
                return d === mappedDays[i - 1] + 1
              })

              const isActive =
                isFuture(new Date(w.endDate)) ||
                isSameDay(new Date(w.endDate), new Date())

              const weekdaysAbbreviations = [
                'Mån',
                'Tis',
                'Ons',
                'Tor',
                'Fre',
                'Lör',
                'Sön',
              ]

              const weekdays = hasMoreThanOneDay
                ? isRange
                  ? `${weekdaysAbbreviations[startDay]} - ${weekdaysAbbreviations[endDay]}`
                  : mappedDays.map((d) => weekdaysAbbreviations[d]).join(', ')
                : weekdaysAbbreviations[mappedDays[0]]

              const onClickHandler = () => {
                setSelected(w)
                setShowModal(true)
              }

              const formatDate = (dateString: string) => {
                return format(new Date(dateString), 'yyyy-MM-dd')
              }

              const formatTime = (dateString: string) => {
                const prefix = format(new Date(), 'MM/dd/yyyy ')
                return format(
                  new Date(prefix + dateString.split('+').join(' +')),
                  'HH:mm'
                )
              }

              const date = isActive
                ? `${formatDate(w.startDate)} - ${formatDate(w.endDate)}`
                : 'Period har passerat'

              const formatLocationAvailability = (
                acceptDigital: boolean,
                acceptPhysical: boolean
              ) => {
                if (acceptDigital && acceptPhysical) return 'Digitalt & Fysiskt'
                if (acceptDigital && !acceptPhysical) return 'Digitalt'
                if (!acceptDigital && acceptPhysical) return 'Fysiskt'
              }

              return (
                <S.TableRow
                  key={w.id}
                  onClick={onClickHandler}
                  $isActive={isActive}
                >
                  <S.FlexSpan>
                    <S.RowText>{date}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>{weekdays}</S.RowText>
                  </S.FlexSpan>

                  {w.afternoonEndTime && w.afternoonStartTime ? (
                    <React.Fragment>
                      <S.FlexSpan>
                        <S.RowText>
                          {formatTime(w.morningStartTime)} {' - '}
                          {formatTime(w.afternoonEndTime)}
                        </S.RowText>
                      </S.FlexSpan>

                      <S.FlexSpan>
                        <S.RowText>
                          {formatTime(w.morningEndTime)} {' - '}
                          {formatTime(w.afternoonStartTime)}
                        </S.RowText>
                      </S.FlexSpan>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <S.FlexSpan>
                        <S.RowText>
                          {formatTime(w.morningStartTime)} {' - '}
                          {formatTime(w.morningEndTime)}
                        </S.RowText>
                      </S.FlexSpan>

                      <S.FlexSpan>
                        <S.RowText>-</S.RowText>
                      </S.FlexSpan>
                    </React.Fragment>
                  )}

                  <S.FlexSpan>
                    <S.RowText>
                      {formatLocationAvailability(
                        w.acceptDigitalMeetings,
                        w.acceptPhysicalMeetings
                      )}
                    </S.RowText>
                  </S.FlexSpan>
                </S.TableRow>
              )
            })}
          </Table.Body>
        }
      />

      {showModal && (
        <SchedulesModal
          selected={selected}
          setSelected={setSelected}
          isOpen={showModal}
          setIsOpen={setShowModal}
          openDeleteModal={(item) => {
            setSelected(item)
            setShowDeleteModal(true)
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          items={selected}
          isLoading={workingTimes.isLoading}
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onDelete={onDeletedHandler}
          title={'Ta bort schema'}
          warningTitle={'Är du säker på att du vill ta bort schemat?'}
          warningMessages={[
            'Du kan inte ångra dig efter att du har tagit bort något.',
          ]}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Lyckad borttagning"
          successTitle="Borttagningen har genomförts"
        />
      )}
    </React.Fragment>
  )
}

export default Schedules
