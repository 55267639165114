import React, { useMemo, useState, useCallback, useEffect } from 'react'
import * as S from './SchedulesModal.style'
import {
  format,
  addMinutes,
  subMinutes,
  startOfDay,
  differenceInMinutes,
} from 'date-fns'
import Select from '@bonliva-components/web/shared/Select/Select'
import Modal from '@bonliva-components/web/shared/Modal'
import Alert from '@bonliva-components/web/shared/Alert'
import DatePicker from '@bonliva-components/web/shared/DatePicker'
import Spinner from '@bonliva-components/web/shared/Spinner'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { IWorkingTime } from '@bonliva-traits/api/types'
import { WeekdaysType } from '@bonliva-traits/api/enums'
import {
  useFetchTreaterProfile,
  useTreaterProfile,
} from '@bonliva-traits/api/treater-profile'

type ITime = {
  value: Date
  label: string
}

type Props = {
  selected?: IWorkingTime
  setSelected: (empty: undefined) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  openDeleteModal?: (item: IWorkingTime) => void
}

const weekdaysNames = ['m', 't', 'o', 't', 'f', 'l', 's']

const SchedulesModal: React.FC<Props> = (props) => {
  const fetchProfile = useFetchTreaterProfile()
  const treater = useTreaterProfile()

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [workTimeStart, _setWorkTimeStart] = useState<Date | null>(null)
  const [workTimeEnd, setWorkTimeEnd] = useState<Date | null>(null)
  const [breakTimeStart, setBreakTimeStart] = useState<Date | null>(null)
  const [breakTimeEnd, _setBreakTimeEnd] = useState<Date | null>(null)
  const [showBreakTime, setShowBreakTime] = useState(false)
  const [digitalCheck, setDigitalCheck] = useState<boolean>(false)
  const [physicalCheck, setPhysicalCheck] = useState<boolean>(false)
  const [weekdays, setWeekdays] = useState<WeekdaysType[]>([])
  const [canHaveBreak, setCanHaveBreak] = useState(false)

  const formatDateHandler = useCallback(
    (dateString: string) => {
      const date = props.selected
        ? new Date(props.selected?.startDate)
        : new Date()

      const prefix = format(date, 'MM/dd/yyyy ')
      return new Date(prefix + dateString.split('+').join(' +'))
    },
    [props.selected]
  )

  const isFilledOut =
    !!startDate &&
    !!endDate &&
    !!workTimeStart &&
    !!workTimeEnd &&
    !!weekdays.length &&
    (!!digitalCheck || !!physicalCheck)

  const workingTimes = useApiState<IWorkingTime>('/v1/treater/working-times')

  useEffect(() => {
    if (!props.selected) return

    setStartDate(new Date(props.selected?.startDate))
    setEndDate(new Date(props.selected?.endDate))
    setWeekdays(props.selected?.weekdays)
    setPhysicalCheck(props.selected?.acceptPhysicalMeetings)
    setDigitalCheck(props.selected?.acceptDigitalMeetings)

    if (
      !props.selected.afternoonEndTime ||
      !props.selected.afternoonStartTime
    ) {
      setWorkTimeStart(formatDateHandler(props.selected?.morningStartTime))
      setWorkTimeEnd(formatDateHandler(props.selected?.morningEndTime))
    } else {
      setWorkTimeStart(formatDateHandler(props.selected?.morningStartTime))
      setWorkTimeEnd(formatDateHandler(props.selected?.afternoonEndTime))
      setBreakTimeStart(formatDateHandler(props.selected.morningEndTime))
      setBreakTimeEnd(formatDateHandler(props.selected.afternoonStartTime))
      setShowBreakTime(true)
    }
  }, [props.selected])

  useEffect(() => {
    if (!workTimeStart || !workTimeEnd) return
    const diff = differenceInMinutes(workTimeEnd, workTimeStart)
    const isMoreThanOneHour = diff > 60
    setCanHaveBreak(isMoreThanOneHour)

    if (!isMoreThanOneHour) {
      setBreakTimeStart(null)
      setBreakTimeEnd(null)
    }
  }, [workTimeStart, workTimeEnd])

  const setShowBreakTimeHandler = useCallback(() => {
    if (!canHaveBreak) return

    setShowBreakTime((prev) => {
      if (prev) {
        setBreakTimeStart(null)
        setBreakTimeEnd(null)
      }

      return !prev
    })
  }, [canHaveBreak])

  const onDismissHandler = useCallback(() => {
    props.setIsOpen(false)
  }, [])

  const setWeekdayHandler = useCallback((day: number) => {
    setWeekdays((prev) =>
      prev.includes(day) ? prev.filter((item) => item !== day) : [...prev, day]
    )
  }, [])

  const formatSelectedDateHandler = useCallback((date: Date | null) => {
    if (!date) return null

    return {
      value: date,
      label: format(date, 'HH:mm'),
    }
  }, [])

  const filterTimesHandler = useCallback(
    (
      times: ITime[],
      { startTime, endTime }: { startTime?: Date | null; endTime?: Date | null }
    ) => {
      if (!startTime && !endTime) return times

      const filteredTimes = times.filter((time) => {
        if (startTime && endTime) {
          return time.value >= startTime && time.value <= endTime
        }

        if (startTime) {
          return time.value >= startTime
        }

        if (endTime) {
          return time.value <= endTime
        }

        return false
      })

      return filteredTimes
    },
    []
  )

  const filterOneHourSpan = (
    startTime: Date | null,
    times: {
      value: Date
      label: string
    }[]
  ) => {
    if (!startTime) return times

    return times.filter((time) => {
      const diff = differenceInMinutes(time.value, startTime)
      return diff % 60 === 0
    })
  }

  const createWokringTimesHandler = useCallback(async () => {
    if (!isFilledOut) return

    const data: Partial<IWorkingTime> = {}

    data.startDate = format(startDate, 'yyyy-MM-dd')
    data.endDate = format(endDate, 'yyyy-MM-dd')
    data.weekdays = weekdays
    data.acceptDigitalMeetings = digitalCheck
    data.acceptPhysicalMeetings = physicalCheck

    const timeOffset = new Date().getTimezoneOffset()

    const timeFormat = (date: Date) => {
      if (timeOffset > 0) {
        return format(addMinutes(date, Math.abs(timeOffset)), 'HH:mm:ss')
      } else if (timeOffset < 0) {
        return format(subMinutes(date, Math.abs(timeOffset)), 'HH:mm:ss')
      } else {
        return format(date, 'HH:mm:ss')
      }
    }

    if (breakTimeStart && breakTimeEnd) {
      data.morningStartTime = timeFormat(workTimeStart)
      data.morningEndTime = timeFormat(breakTimeStart)
      data.afternoonStartTime = timeFormat(breakTimeEnd)
      data.afternoonEndTime = timeFormat(workTimeEnd)
    } else {
      data.morningStartTime = timeFormat(workTimeStart)
      data.morningEndTime = timeFormat(workTimeEnd)
      data.afternoonStartTime = null
      data.afternoonEndTime = null
    }

    if (!props.selected) await workingTimes.post({ data })
    else await workingTimes.put({ data }, props.selected.id)
    fetchProfile()
    props.setIsOpen(false)
  }, [
    startDate,
    endDate,
    workTimeStart,
    workTimeEnd,
    breakTimeStart,
    breakTimeEnd,
    weekdays,
    isFilledOut,
    digitalCheck,
    physicalCheck,
  ])

  // every 60 min from 00:10 to 23:00
  const times = useMemo(() => {
    const date = props.selected
      ? new Date(props.selected?.startDate)
      : new Date()

    const start = startOfDay(date)

    const times = Array.from({ length: 48 }, (_, i) =>
      addMinutes(start, i * 30)
    ).map((date) => ({
      value: date,
      label: format(date, 'HH:mm'),
    }))

    return times
  }, [props.selected])

  const workTimeStartTimes = useMemo(() => {
    const endTime = workTimeEnd && subMinutes(workTimeEnd, 60)
    return filterTimesHandler(times, { endTime })
  }, [workTimeEnd])

  const workTimeEndTimes = useMemo(() => {
    if (showBreakTime && breakTimeEnd) {
      const startTime = breakTimeEnd && addMinutes(breakTimeEnd, 60)
      const timeSLotsAfterStartTime = filterTimesHandler(times, { startTime })
      return filterOneHourSpan(breakTimeEnd, timeSLotsAfterStartTime)
    } else {
      const startTime = workTimeStart && addMinutes(workTimeStart, 60)
      const timeSLotsAfterStartTime = filterTimesHandler(times, { startTime })
      return filterOneHourSpan(workTimeStart, timeSLotsAfterStartTime)
    }
  }, [workTimeStart, breakTimeEnd, showBreakTime])

  const breakTimeStartTimes = useMemo(() => {
    if (breakTimeEnd) {
      const startTime = workTimeStart && addMinutes(workTimeStart, 60)
      const endTime = breakTimeEnd && subMinutes(breakTimeEnd, 30)
      const timeSlotsInBetweenWorktimes = filterTimesHandler(times, {
        startTime,
        endTime,
      })
      return filterOneHourSpan(workTimeStart, timeSlotsInBetweenWorktimes)
    } else {
      const startTime = workTimeStart && addMinutes(workTimeStart, 60)
      const endTime = workTimeEnd && subMinutes(workTimeEnd, 60)
      const timeSlotsInBetweenWorktimes = filterTimesHandler(times, {
        startTime,
        endTime,
      })
      return filterOneHourSpan(workTimeStart, timeSlotsInBetweenWorktimes)
    }
  }, [workTimeStart, breakTimeEnd, workTimeEnd])

  const breakTimeEndTimes = useMemo(() => {
    if (breakTimeStart) {
      const startTime = addMinutes(breakTimeStart, 30)
      const endTime = workTimeEnd && subMinutes(workTimeEnd, 60)
      return filterTimesHandler(times, { startTime, endTime })
    } else {
      const startTime = workTimeStart && addMinutes(workTimeStart, 60)
      const endTime = workTimeEnd && subMinutes(workTimeEnd, 60)
      return filterTimesHandler(times, { startTime, endTime })
    }
  }, [workTimeStart, breakTimeStart, workTimeEnd])

  const title = useMemo(() => {
    if (props.selected) return 'Redigera arbetstid'
    else return 'Lägg till arbetstid'
  }, [isFilledOut])

  const alertMessage = useMemo(() => {
    const m: string[] = []

    if (!isFilledOut) {
      m.push('Du måste fylla i alla fält för att kunna spara.')
    }

    return m
  }, [isFilledOut, canHaveBreak])

  const setBreakTimeEnd = (date: Date | null) => {
    if (date && workTimeEnd) {
      if (format(date, 'mm') !== format(workTimeEnd, 'mm')) {
        setWorkTimeEnd(null)
      }
    }

    _setBreakTimeEnd(date)
  }

  const setWorkTimeStart = (date: Date | null) => {
    if (date && workTimeStart) {
      if (format(date, 'mm') !== format(workTimeStart, 'mm')) {
        setWorkTimeEnd(null)
        _setBreakTimeEnd(null)
        setBreakTimeStart(null)
      }
    }

    _setWorkTimeStart(date)
  }

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={onDismissHandler}>
        <S.SchedulesModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{title}</S.Title>
          </S.Header>

          <S.Content>
            <Alert
              type="info"
              title="Rullande veckoschema"
              messages={alertMessage}
            />

            <S.FlexWrapper>
              <S.SelectTimeWrapper>
                <S.Label>Startdatum</S.Label>
                <DatePicker
                  onChange={setStartDate}
                  value={startDate}
                  maxDate={endDate}
                  placeholder="Från"
                />
              </S.SelectTimeWrapper>

              <S.SelectTimeWrapper>
                <S.Label>Slutdatum</S.Label>
                <DatePicker
                  onChange={setEndDate}
                  value={endDate}
                  minDate={startDate}
                  placeholder="Till"
                />
              </S.SelectTimeWrapper>
            </S.FlexWrapper>

            <S.Label>Veckodagar</S.Label>
            <S.FlexWrapper>
              {weekdaysNames.map((day, i) => {
                const number = i + 1 > 6 ? 0 : i + 1

                return (
                  <S.SelectDayButton
                    key={number}
                    $isSelected={weekdays.includes(number)}
                    onClick={() => setWeekdayHandler(number)}
                  >
                    {day}
                  </S.SelectDayButton>
                )
              })}
            </S.FlexWrapper>

            <S.FlexWrapper>
              <S.SelectTimeWrapper>
                <S.Label>Arbetstid</S.Label>
                <Select
                  placeholder="Från"
                  options={workTimeStartTimes}
                  value={formatSelectedDateHandler(workTimeStart)}
                  onChange={(e) => setWorkTimeStart(e?.value || null)}
                />
              </S.SelectTimeWrapper>

              <S.SelectTimeWrapper>
                <Select
                  placeholder="Till"
                  isDisabled={!workTimeStart}
                  options={workTimeEndTimes}
                  value={formatSelectedDateHandler(workTimeEnd)}
                  onChange={(e) => setWorkTimeEnd(e?.value || null)}
                />
              </S.SelectTimeWrapper>
            </S.FlexWrapper>
            <S.Label>Vill du lägga till en paus?</S.Label>
            <S.FlexClickWrapper
              disabled={!canHaveBreak}
              onClick={setShowBreakTimeHandler}
            >
              <S.Checkbox>
                {showBreakTime && <S.Check src="Check" />}
              </S.Checkbox>
              <S.CheckboxLabel>Lägg till paus</S.CheckboxLabel>
            </S.FlexClickWrapper>

            {showBreakTime && (
              <S.FlexWrapper>
                <S.SelectTimeWrapper>
                  <S.Label>Paus</S.Label>
                  <Select
                    doNotUseMenuPortalTarget={true}
                    placeholder="Från"
                    options={breakTimeStartTimes}
                    value={formatSelectedDateHandler(breakTimeStart)}
                    onChange={(e) => setBreakTimeStart(e?.value || null)}
                    isDisabled={!workTimeStart || !workTimeEnd}
                  />
                </S.SelectTimeWrapper>

                <S.SelectTimeWrapper>
                  <Select
                    doNotUseMenuPortalTarget={true}
                    placeholder="Till"
                    value={formatSelectedDateHandler(breakTimeEnd)}
                    onChange={(e) => setBreakTimeEnd(e?.value || null)}
                    options={breakTimeEndTimes}
                    isDisabled={!workTimeStart || !workTimeEnd}
                  />
                </S.SelectTimeWrapper>
              </S.FlexWrapper>
            )}

            <S.Label>Jag kan ta samtal</S.Label>
            <S.FlexWrapper>
              <S.FlexClickWrapper
                onClick={() => setDigitalCheck((prev) => !prev)}
              >
                <S.Checkbox>
                  {digitalCheck && <S.Check src="Check" />}
                </S.Checkbox>
                <S.CheckboxLabel>Digitalt</S.CheckboxLabel>
              </S.FlexClickWrapper>

              <S.FlexClickWrapper
                disabled={!treater.data.physicalLocationId}
                onClick={() => {
                  setPhysicalCheck((prev) => !prev)
                }}
              >
                <S.Checkbox>
                  {physicalCheck && <S.Check src="Check" />}
                </S.Checkbox>
                <S.CheckboxLabel>Fysiskt</S.CheckboxLabel>
              </S.FlexClickWrapper>
              {!treater.data.physicalLocationId && (
                <S.AlertMessage>
                  Om du vill ta emot fysiska bokningar så måste du lägga till en
                  mottagningsadress under mitt konto.
                </S.AlertMessage>
              )}
            </S.FlexWrapper>
          </S.Content>

          <S.Footer>
            {!!props.openDeleteModal && !!props.selected && (
              <Modal.DismissButton
                onClick={() => {
                  if (!props.selected) return
                  if (!props.openDeleteModal) return
                  props.openDeleteModal(props.selected)
                }}
              >
                <S.Delete>Ta bort schema</S.Delete>
              </Modal.DismissButton>
            )}

            <Modal.DismissButton>
              <S.Cancel>Avbryt</S.Cancel>
            </Modal.DismissButton>

            <S.Create
              disabled={!isFilledOut || workingTimes.isLoading}
              onClick={createWokringTimesHandler}
            >
              {workingTimes.isLoading ? <Spinner /> : 'Spara'}
            </S.Create>
          </S.Footer>
        </S.SchedulesModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default SchedulesModal
