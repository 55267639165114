import React, { useState, useCallback, useMemo, useEffect } from 'react'
import * as S from './CreateBookingModal.style'
import Modal from '@bonliva-components/web/shared/Modal'
import { Icon } from '@bonliva-traits/web-icons'
import DatePicker from '@bonliva-components/web/shared/DatePicker'
import Select from '@bonliva-components/web/shared/Select'
import { addMinutes, format, set, startOfDay } from 'date-fns'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { IPatient, ITreatmentCategory } from '@bonliva-traits/api/types'
import RadioButton from '@bonliva-components/web/shared/RadioButton'
import { BookingMeetingType } from '@bonliva-traits/api/enums'
import Spinner from '@bonliva-components/web/shared/Spinner'
import { useApi } from '@bonliva-traits/api'
import { SuccessModal } from './components/SuccessModal'
import { useToast } from '@bonliva-traits/web-toasts'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import * as Axios from 'axios'
import { CalendarRef } from '@bonliva-components/web/shared/Calendar'
import { RefObject } from '@fullcalendar/core'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Personnummer from 'personnummer'
import * as zod from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  calendarRefreshRef: RefObject<CalendarRef>
}

const bookingBase = zod.object({
  startDate: zod.date({
    required_error: 'Du måste välja ett datum',
    invalid_type_error: 'Du måste välja ett datum',
  }),
  startTime: zod.date({ invalid_type_error: 'Du måste välja en tid' }),
  treatmentId: zod.string({
    invalid_type_error: 'Du måste välja en behandling',
  }),
  meetingType: zod.nativeEnum(BookingMeetingType),
})

const formSchema = zod.discriminatedUnion('newPatient', [
  bookingBase.extend({
    newPatient: zod.literal(true),
    patientPersonalNumber: zod
      .string({
        required_error: 'Du måste fylla i ett personnummer',
        invalid_type_error: 'Du måste fylla i ett personnummer',
      })
      .regex(/^\d{12}$/, { message: 'Du måste fylla i 12 siffror' })
      .refine(
        (val) => {
          return Personnummer.valid(val)
        },
        { message: 'Du måste fylla i ett giltigt personnummer' }
      ),
    patientName: zod
      .string({ required_error: 'Du måste fylla i namn' })
      .min(2, { message: 'Du måste fylla i namn' }),
    patientPhone: zod
      .string({ required_error: 'Du måste fylla i namn' })
      .regex(/^\d{10}$/, {
        message: 'Du måste fylla i ett giltigt telefonnummer',
      }),
  }),
  bookingBase.extend({
    newPatient: zod.literal(false),
    patientId: zod.string({ invalid_type_error: 'Du måste välja en patient' }),
  }),
])

type FormData = {
  startDate: Date
  startTime: Date | null
  treatmentId: string | null
  meetingType: BookingMeetingType
  patientId: string | null
  newPatient: boolean
  patientPersonalNumber: string
  patientName: string
  patientPhone: string
}

const CreateBookingModal: React.FC<Props> = (props) => {
  const toast = useToast()
  const treater = useTreaterProfile()

  const {
    control,
    watch,
    setValue,
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      startTime: null,
      treatmentId: null,
      patientId: null,
      newPatient: false,
      meetingType: BookingMeetingType.Digital,
    },
    resolver: zodResolver(formSchema),
  })

  const patientPersonalNumber = watch('patientPersonalNumber')
  const patientId = watch('patientId')
  const startDate = watch('startDate')

  const [loading, setLoading] = useState(false)

  const api = useApi()
  const [showNewPatientForm, setShowNewPatientForm] = useState(false)
  const patients = useApiState<IPatient[]>('/v1/treaters/patients')
  const treatmentCategories = useApiState<ITreatmentCategory[]>(
    `v1/treatment-categories`
  )
  const [existingPatient, setExistingPatient] = useState<IPatient | null>(null)

  const getDataHandler = async () => {
    await patients.get()
    await treatmentCategories.get()
  }

  useEffect(() => {
    getDataHandler()
  }, [])

  const times = useMemo(() => {
    const chosenDate = startDate ? new Date(startDate) : new Date()

    const start = addMinutes(startOfDay(chosenDate), 60)

    const times = Array.from({ length: 48 }, (_, i) =>
      addMinutes(start, i * 30)
    ).map((date) => ({
      value: date,
      label: format(date, 'HH:mm'),
    }))

    const now = new Date()
    return times.filter((time) => time.value > now)
  }, [startDate])

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (formData) => {
      const getOrCreatePatient = async (formData: FormData) => {
        const _patients = existingPatient
          ? patients.data?.concat([existingPatient])
          : patients.data

        if (formData.patientId) {
          return _patients?.find((p) => p.id === formData.patientId)
        }

        const newPatient = await api.post<IPatient>(`v1/treaters/patients`, {
          personalNumber: formData.patientPersonalNumber,
          name: formData.patientName,
          phone: formData.patientPhone,
        })

        return newPatient.data
      }

      setLoading(true)
      try {
        const startDate = set(formData.startDate, {
          hours: formData.startTime?.getHours(),
          minutes: formData.startTime?.getMinutes(),
          seconds: 0,
        })

        const patient = await getOrCreatePatient(formData)

        if (!patient) {
          throw new Error('Patient not found')
        }

        await api.post(`v1/treaters/appointments`, {
          patientId: patient?.id,
          meetingType: formData.meetingType,
          startDate,
          endDate: addMinutes(startDate || new Date(), 45),
          treatmentId: formData.treatmentId,
        })

        props.setIsOpen(false)
        props.calendarRefreshRef.current?.refreshCalendar()
        toast.addToast({
          id: 'success',
          toast: (
            <SuccessModal
              isOpen={props.isOpen}
              setIsOpen={props.setIsOpen}
              meetingStart={startDate}
              patientName={patient.name}
              date={startDate}
              meetingType={formData.meetingType}
            />
          ),
        })
      } catch (error) {
        if (Axios.isAxiosError(error)) {
          if (error.response?.data.status === 409) {
            setError('root', {
              message: 'Tiden är redan bokad. Vänligen välj en annan tid.',
            })
          } else {
            setError('root', {
              message:
                'Något gick fel vid bokningen, vänligen kontrollera att alla fält är korrekt ifyllda och försök igen.',
            })
          }
        } else {
          setError('root', {
            message:
              'Något gick fel vid bokningen, vänligen kontrollera att alla fält är korrekt ifyllda och försök igen.',
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [patients.data, existingPatient]
  )

  const checkIfPatientExists = useCallback(async () => {
    try {
      const personalNumber = getValues('patientPersonalNumber')

      if (!Personnummer.valid(personalNumber)) return

      const res = await api.get<IPatient>(
        `v1/treaters/patients/${personalNumber}/exists`
      )
      if (res.data) {
        setValue('newPatient', false)
        setValue('patientId', res.data.id)
        setValue('patientName', res.data.name)
        setValue('patientPhone', res.data.phone || '')
        clearErrors('patientPersonalNumber')
        clearErrors('patientName')
        clearErrors('patientPhone')
        setExistingPatient(res.data)
      } else {
        setExistingPatient(null)
      }
    } catch (error) {
      throw new Error('Error checking if patient exists')
    }
  }, [patientPersonalNumber])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents
        onDismiss={() => props.setIsOpen(false)}
        maxWidth={600}
        maxHeight={'100vh'}
      >
        <S.BookingModalWrapper>
          <S.FlexWrapperSpaceBetween>
            <S.BookingModalTitle>Boka nytt samtal</S.BookingModalTitle>
            <S.CloseButtonWrapper onClick={() => props.setIsOpen(false)}>
              <Icon color="#000" width={24} height={24} src="Close" />
            </S.CloseButtonWrapper>
          </S.FlexWrapperSpaceBetween>

          <S.Divider />

          <S.FlexWrapper>
            <Icon color="#000" width={24} height={24} src="Calender" />
            <S.FlexWrapperCol>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    placeholder="Välj datum"
                  />
                )}
                name="startDate"
              />
              {errors.startDate && (
                <S.ErrorMessage>{errors.startDate.message}</S.ErrorMessage>
              )}
            </S.FlexWrapperCol>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <Icon color="#000" width={24} height={24} src="Clock" />
            <S.FlexWrapperCol>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Från"
                    options={times}
                    value={
                      value
                        ? {
                            value: value,
                            label: format(value, 'HH:mm'),
                          }
                        : null
                    }
                    onChange={(e) => onChange(e ? e.value : null)}
                  />
                )}
                name="startTime"
              />
              {errors.startTime && (
                <S.ErrorMessage>{errors.startTime.message}</S.ErrorMessage>
              )}
            </S.FlexWrapperCol>
          </S.FlexWrapper>

          <S.FlexWrapperCol>
            <S.FlexWrapper>
              <Icon color="#000" width={24} height={24} src="SecondaryAvatar" />
              <S.FlexWrapperCol>
                <Controller
                  control={control}
                  name="patientId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Välj patient"
                      options={patients.data}
                      value={patients.data?.find(
                        (patient) => patient.id === value
                      )}
                      onChange={(e) => onChange(e ? e.id : null)}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isDisabled={showNewPatientForm}
                    />
                  )}
                />
                {errors.patientId && (
                  <S.ErrorMessage>{errors.patientId.message}</S.ErrorMessage>
                )}
              </S.FlexWrapperCol>
            </S.FlexWrapper>

            <S.AddNewPatientButton
              onClick={() => {
                setValue('patientId', null)
                setValue('newPatient', !showNewPatientForm)
                clearErrors('patientId')
                setShowNewPatientForm(!showNewPatientForm)
              }}
            >
              {showNewPatientForm ? 'Välj befintlig patient' : '+ Ny patient'}
            </S.AddNewPatientButton>

            {showNewPatientForm && (
              <S.NewPatientWrapper>
                <S.FlexWrapperCol>
                  <S.TextInput
                    {...register('patientPersonalNumber', {
                      onBlur: checkIfPatientExists,
                      disabled: !!patientId,
                    })}
                    placeholder="Personnummer (12 siffror)"
                  />
                  {errors.patientPersonalNumber && (
                    <S.ErrorMessage>
                      {errors.patientPersonalNumber.message}
                    </S.ErrorMessage>
                  )}
                </S.FlexWrapperCol>

                <S.FlexWrapperCol>
                  <S.TextInput
                    {...register('patientName', { disabled: !!patientId })}
                    placeholder="För- och efternamn"
                  />
                  {errors.patientName && (
                    <S.ErrorMessage>
                      {errors.patientName.message}
                    </S.ErrorMessage>
                  )}
                </S.FlexWrapperCol>

                <S.FlexWrapperCol>
                  <S.TextInput
                    {...register('patientPhone', { disabled: !!patientId })}
                    placeholder="Telefonnummer"
                  />
                  {errors.patientPhone && (
                    <S.ErrorMessage>
                      {errors.patientPhone.message}
                    </S.ErrorMessage>
                  )}
                </S.FlexWrapperCol>
              </S.NewPatientWrapper>
            )}
          </S.FlexWrapperCol>

          <S.FlexWrapper>
            <Icon color="#000" width={24} height={24} src="HeartChart" />
            <S.FlexWrapperCol>
              <Controller
                control={control}
                name="treatmentId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Välj inriktning"
                    options={treatmentCategories.data}
                    getOptionLabel={(option) => option.category}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => onChange(e ? e.id : null)}
                    value={treatmentCategories.data?.find(
                      (c) => c.id === value
                    )}
                  />
                )}
              />
              {errors.treatmentId && (
                <S.ErrorMessage>{errors.treatmentId.message}</S.ErrorMessage>
              )}
            </S.FlexWrapperCol>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <Icon color="#000" src="Location" width={24} height={24} />
            <Controller
              control={control}
              name="meetingType"
              render={({ field: { onChange, value } }) => (
                <RadioButton
                  label="Digitalt"
                  isSelected={value === BookingMeetingType.Digital}
                  onClick={() => {
                    onChange(BookingMeetingType.Digital)
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="meetingType"
              render={({ field: { onChange, value } }) => (
                <RadioButton
                  label="På plats"
                  disabled={!treater.data.physicalLocationId}
                  isSelected={value === BookingMeetingType.Physical}
                  onClick={() => {
                    onChange(BookingMeetingType.Physical)
                  }}
                />
              )}
            />
          </S.FlexWrapper>
          {errors.root && (
            <S.ErrorMessage>{errors.root.message}</S.ErrorMessage>
          )}

          {!treater.data.physicalLocationId && (
            <S.AlertMessage>
              Om du vill ta emot fysiska bokningar så måste du lägga till en
              mottagningsadress under mitt konto.
            </S.AlertMessage>
          )}
          <S.FlexEndWrapper>
            <S.CancelButton onClick={() => props.setIsOpen(false)}>
              Avbryt
            </S.CancelButton>
            <S.ConfirmButton onClick={handleSubmit(onSubmit)}>
              {loading ? <Spinner color="white" /> : 'Boka möte'}
            </S.ConfirmButton>
          </S.FlexEndWrapper>
        </S.BookingModalWrapper>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default CreateBookingModal
