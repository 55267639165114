export const Images = {
  SplashBackground: require('./splash-background.png'),
  MatchingBackground: require('./matching-background.png'),
  ConfirmationBackground: require('./confirmation-background.png'),
  Onboarding1: require('../images/onboarding1.png'),
  Onboarding2: require('../images/onboarding2.png'),
  Onboarding3: require('../images/onboarding3.png'),
  Onboarding4: require('../images/onboarding4.png'),
  Login: require('../images/login.png'),
  Login1: require('../images/login1.png'),
  Login2: require('../images/login2.png'),
  Login3: require('../images/login3.png'),
  Login4: require('../images/login4.png'),
  Dash1: require('../images/dash1.jpg'),
  Dash2: require('../images/dash2.jpg'),
  Dash3: require('../images/dash3.jpg'),
  Home1: require('../images/home1.jpg'),
  Home2: require('../images/home2.jpg'),
  Home3: require('../images/home3.jpg'),
  Default: require('../images/default.png'),
  DefaultProfile: require('../images/default-profile.png'),
  MediaLibrary: require('../images/media-library.png'),
  MicrosoftOutlook: require('../images/MicrosoftOutlook.png'),
}
