import React, { useMemo, useCallback, useEffect, useState } from 'react'
import * as S from './DashboardTodaysMeetings.style'
import { useNavigate } from 'react-router-dom'
import { BookingStatus, UserType } from '@bonliva-traits/api/enums'
import { IAppointment } from '@bonliva-traits/api/types'
import { endOfDay, startOfDay, format } from 'date-fns'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Alert from '@bonliva-components/web/shared/Alert'
import Spinner from '@bonliva-components/web/shared/Spinner'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import AppointmentInfoModal from '../AppointmentInfoModal'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal'
import useInterval from '@bonliva-traits/hooks/useInterval'
import {
  useAppointments,
  useFetchAppointments,
} from '@bonliva-traits/api/appointments'

const DashboardTodaysMeetings = () => {
  const navigate = useNavigate()
  const fetchAppointments = useFetchAppointments(UserType.Treater)
  const appointments = useAppointments()

  const [currentTime, setCurrentTime] = useState(new Date())
  const [selected, setSelected] = useState<IAppointment>()
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [comment, setComment] = useState('')

  const appointment = useApiState<IAppointment>(`/v1/treaters/appointments`)

  useInterval(() => setCurrentTime(new Date()), 60000)

  useInterval(() => fetchAppointmentsHandler(), 60000)

  useEffect(() => {
    if (showDeleteModal) setComment('')
  }, [showDeleteModal])

  const fetchAppointmentsHandler = useCallback(() => {
    const today = new Date().toISOString()

    fetchAppointments({
      fromDate: today,
      toDate: today,
      relations: ['patient', 'treatment'],
      status: [
        BookingStatus.Created,
        BookingStatus.Paid,
        BookingStatus.Completed,
        BookingStatus.NoShow,
      ],
    })
  }, [])

  const navigateToPatientHandler = useCallback((patientId: string) => {
    navigate(`/patients/${patientId}`)
  }, [])

  const showInfoModalHandler = useCallback((a: IAppointment) => {
    setSelected(a)
    setInfoModalOpen(true)
  }, [])

  const onDeleteHandler = useCallback(async () => {
    if (!selected) return
    const data = { cancelReason: comment }
    await appointment.put({ data }, `${selected.id}/cancel`)
    fetchAppointmentsHandler()
    setShowDeleteModal(false)
    setShowSuccessModal(true)
    setComment('')
  }, [selected, comment])

  const filterToday = useCallback(
    (a: IAppointment) => {
      return (
        new Date(a.startDate) > startOfDay(currentTime) &&
        new Date(a.endDate) < endOfDay(currentTime) &&
        [
          BookingStatus.Created,
          BookingStatus.Paid,
          BookingStatus.Completed,
        ].includes(a.status)
      )
    },
    [appointments, currentTime]
  )

  const numberOfAppointments = useMemo(
    () => appointments.data.filter(filterToday).length,
    [appointments]
  )

  const deleteModalContent = useMemo(() => {
    if (!selected) return null
    const patientName = selected.patient?.name

    return (
      <React.Fragment>
        <Alert
          type="info"
          title="Kom ihåg att skriva en anledningen till avbokningen."
        />

        <S.Label>Skriv en kommentar till {patientName}</S.Label>

        <S.CommentField
          placeholder="Ange en anledning för avbokningen här."
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </React.Fragment>
    )
  }, [selected, comment])

  const successModalContent = useMemo(() => {
    if (!selected) return null
    const patientName = selected.patient?.name
    const date = format(new Date(selected?.startDate), 'yyyy-MM-dd')
    const startDate = format(new Date(selected?.startDate), 'HH:mm')
    const endDate = format(new Date(selected?.endDate), 'HH:mm')
    return (
      <React.Fragment>
        <S.SuccessModalTitle>
          Ditt samtal med {patientName} är nu avbokat.
        </S.SuccessModalTitle>

        <S.InfoWrapper>
          <S.Info>
            Datum:
            <S.Bold>{date}</S.Bold>
          </S.Info>

          <S.Info>
            Tid:
            <S.Bold>
              {startDate} - {endDate}
            </S.Bold>
          </S.Info>
        </S.InfoWrapper>
      </React.Fragment>
    )
  }, [selected])

  return (
    <S.DashboardTodaysMeetings>
      <S.Header>
        <S.Title>
          Dagens samtal <S.TitleNumber>({numberOfAppointments})</S.TitleNumber>
        </S.Title>

        <S.Link to="/calendar">
          Se kalender <S.ChevronRight src="ChevronRight" />
        </S.Link>
      </S.Header>

      <S.MeetingsList>
        {appointments.data.filter(filterToday).map((appointment) => {
          const treatment = appointment.treatment
          const patient = appointment.patient

          return (
            <S.ListItem
              key={appointment.id}
              onClick={() => navigateToPatientHandler(appointment.patientId)}
            >
              <div>
                <S.ListItemPatientName>{patient?.name}</S.ListItemPatientName>

                <S.ListItemAppointmentCause>
                  {treatment?.category}
                  {' - '}
                  {format(new Date(appointment.startDate), 'HH:mm')} -{' '}
                  {format(new Date(appointment.endDate), 'HH:mm')}
                </S.ListItemAppointmentCause>
              </div>

              <S.ShowModal
                onClick={(event) => {
                  event.stopPropagation()
                  showInfoModalHandler(appointment)
                }}
              >
                Visa möte
              </S.ShowModal>
            </S.ListItem>
          )
        })}

        {appointments.hasLoaded && numberOfAppointments === 0 && (
          <S.NoMeetingsMessage>
            Du har inga inbokade samtal idag
          </S.NoMeetingsMessage>
        )}

        {!appointments.data && appointments.isLoading && (
          <S.LoadingWrapper>
            <Spinner color="neutral" />
          </S.LoadingWrapper>
        )}
      </S.MeetingsList>

      {selected && (
        <React.Fragment>
          <AppointmentInfoModal
            appointment={selected}
            isOpen={infoModalOpen}
            setIsOpen={setInfoModalOpen}
            setCancelModalOpen={setShowDeleteModal}
          />

          <DeleteModal
            items={[selected]}
            isOpen={showDeleteModal}
            setIsOpen={setShowDeleteModal}
            isLoading={appointment.isLoading}
            hasError={appointment.hasError}
            onDelete={onDeleteHandler}
            onDeleteTitle="Avboka"
            disableOnDelete={!comment}
            title="Avboka möte"
            warningTitle="Är du säker på att du vill avboka den här bokningen?"
            warningMessages={['Om du avbokar går det inte att ångra.']}
            errorTitle="Något gick fel med avbokningen."
            content={deleteModalContent}
          />

          <SuccessModal
            isOpen={showSuccessModal}
            setIsOpen={setShowSuccessModal}
            title="Mötet är avbokat"
            successTitle="Tiden är nu avbokad."
            content={successModalContent}
          />
        </React.Fragment>
      )}
    </S.DashboardTodaysMeetings>
  )
}

export default DashboardTodaysMeetings
