import React, { useMemo, useState, useEffect, useCallback } from 'react'
import * as S from './AppointmentInfoModal.style'
import { IAppointment, ITreatmentFormAnswers } from '@bonliva-traits/api/types'
import { addMinutes, format, startOfDay } from 'date-fns'
import useInterval from '@bonliva-traits/hooks/useInterval'
import Modal from '@bonliva-components/web/shared/Modal'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { StartMeetingButton } from '@bonliva-components/web/shared/StartMeetingButton/StartMeetingButton'
import { Chip } from './Chip/Chip'
import { ButtonPrimary, ButtonSecondary } from '@bonliva-ui/web'
import { BookingMeetingType } from '@bonliva-traits/api/enums'
import * as zod from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { Icon } from '@bonliva-traits/web-icons'
import DatePicker from '@bonliva-components/web/shared/DatePicker'
import Select from '@bonliva-components/web/shared/Select'
import * as Axios from 'axios'
import { useApi } from '@bonliva-traits/api'
import { useToast } from '@bonliva-traits/web-toasts'
import { CalendarRef } from '@bonliva-components/web/shared/Calendar'
import { RefObject } from '@fullcalendar/core'

type Props = {
  appointment: IAppointment
  calendarRefreshRef: RefObject<CalendarRef>
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setCancelModalOpen: (isOpen: boolean) => void
}

type FormData = {
  date: Date
  time: Date
  meetingType: string
}

const formSchema = zod.object({
  newDate: zod.date({
    required_error: 'Du måste välja ett datum',
    invalid_type_error: 'Du måste välja ett datum',
  }),
  newTime: zod.date({ invalid_type_error: 'Du måste välja en tid' }),
  newMeetingType: zod.nativeEnum(BookingMeetingType),
})

const AppointmentInfoModal: React.FC<Props> = (props) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  const form = useForm<FormData>({
    defaultValues: {
      date: new Date(props.appointment.startDate),
      time: new Date(props.appointment.startDate),
      meetingType: props.appointment.meetingType,
    },
    resolver: zodResolver(formSchema),
  })

  const newDate = form.watch('date')
  const newTime = form.watch('time')
  const newMeetingType = form.watch('meetingType') as BookingMeetingType

  const setNewMeetingType = (meetingType: BookingMeetingType) => {
    form.setValue('meetingType', meetingType)
  }

  const [editMode, setEditMode] = useState<boolean>(false)

  const formQuestions = useApiState<ITreatmentFormAnswers>(
    `/v1/treaters/treatment-form/booking/${props.appointment.id}/answers`
  )
  const api = useApi()
  const toast = useToast()

  useEffect(() => {
    formQuestions.get()
  }, [])

  const start = new Date(props.appointment.startDate).getTime()
  const end = new Date(props.appointment.endDate).getTime()
  const current = currentTime.getTime()
  const moreThanFifteenMinLeft = start - current > 15 * 60 * 1000

  useInterval(
    () => setCurrentTime(new Date()),
    moreThanFifteenMinLeft ? 60000 : 1000,
    []
  )
  const rows = useMemo(() => {
    return [
      {
        label: 'Namn',
        type: 'patientName',
        value: props.appointment.patient?.name,
      },
      {
        label: 'Personnummer',
        type: 'patientPersonalNumber',
        value: props.appointment.patient?.personalNumber,
      },
      {
        label: 'Datum',
        type: 'date',
        value: format(newDate, 'yyyy-MM-dd'),
      },
      {
        label: 'Tid',
        type: 'time',
        value: format(newTime, 'HH:mm'),
      },
      {
        label: 'Inriktning',
        type: 'treatmentCategory',
        value: props.appointment.treatment?.category,
      },
    ]
  }, [
    newDate,
    newTime,
    props.appointment.patient?.name,
    props.appointment.patient?.personalNumber,
    props.appointment.treatment?.category,
  ])

  const times = useMemo(() => {
    const chosenDate = newDate ? new Date(newDate) : new Date()

    const start = addMinutes(startOfDay(chosenDate), 60)

    const times = Array.from({ length: 48 }, (_, i) =>
      addMinutes(start, i * 30)
    ).map((date) => ({
      value: date,
      label: format(date, 'HH:mm'),
    }))

    const now = new Date()
    return times.filter((time) => time.value > now)
  }, [newDate])

  const treatmentFormQuestionMap = useMemo(
    () => ['Aldrig', 'Sällan', 'Ibland', 'Ofta', 'Alltid'],
    []
  )

  const doSave = useCallback(() => {
    const formData = form.getValues()

    api
      .put(`v1/treaters/appointments/${props.appointment.id}`, formData)
      .then(() => {
        toast.addToast({
          id: 'success',
          toast: <span>Appointment updated successfully!</span>,
        })
        props.calendarRefreshRef.current?.refreshCalendar()
        setEditMode(false)
      })
      .catch((error) => {
        if (Axios.isAxiosError(error)) {
          if (error.response?.data.status === 409) {
            form.setError('root', {
              message: 'Tiden är redan bokad. Vänligen välj en annan tid.',
            })
          } else {
            form.setError('root', {
              message:
                'Något gick fel vid bokningen, vänligen kontrollera att alla fält är korrekt ifyllda och försök igen.',
            })
          }
        } else {
          form.setError('root', {
            message:
              'Något gick fel vid bokningen, vänligen kontrollera att alla fält är korrekt ifyllda och försök igen.',
          })
        }
      })
  }, [
    setEditMode,
    api,
    props.appointment.id,
    toast,
    props.calendarRefreshRef.current,
    form,
  ])

  const doEdit = useCallback(() => {
    setEditMode(true)
  }, [setEditMode])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.AppointmentInfoModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{props.appointment.patient?.name}</S.Title>

            <S.Link to={`/patients/${props.appointment.patient?.id}`}>
              Gå till patientprofil <S.ChevronRight src="ChevronRight" />
            </S.Link>
          </S.Header>

          <Chip
            editMode={editMode}
            meetingType={newMeetingType}
            setMeetingType={setNewMeetingType}
            type={props.appointment.type}
            referral={!!props.appointment.referralPlanId}
          />

          <S.Content>
            <S.Grid>
              {rows.map((row) => (
                <React.Fragment key={row.label}>
                  {row.type == 'date' && editMode && (
                    <S.FlexWrapper>
                      <Icon
                        color="#000"
                        width={24}
                        height={24}
                        src="Calender"
                      />
                      <S.FlexWrapperCol>
                        <Controller
                          control={form.control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              onChange={onChange}
                              value={value}
                              placeholder="Välj datum"
                            />
                          )}
                          name="date"
                        />
                        {form.formState.errors.date && (
                          <S.ErrorMessage>
                            {form.formState.errors.date.message}
                          </S.ErrorMessage>
                        )}
                      </S.FlexWrapperCol>
                    </S.FlexWrapper>
                  )}
                  {row.type == 'time' && editMode && (
                    <S.FlexWrapper>
                      <Icon color="#000" width={24} height={24} src="Clock" />
                      <S.FlexWrapperCol>
                        <Controller
                          control={form.control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              placeholder="Från"
                              options={times}
                              value={
                                value
                                  ? {
                                      value: value,
                                      label: format(value, 'HH:mm'),
                                    }
                                  : null
                              }
                              onChange={(e) => onChange(e ? e.value : null)}
                            />
                          )}
                          name="time"
                        />
                        {form.formState.errors.time && (
                          <S.ErrorMessage>
                            {form.formState.errors.time.message}
                          </S.ErrorMessage>
                        )}
                      </S.FlexWrapperCol>
                    </S.FlexWrapper>
                  )}
                  {(!editMode || !['date', 'time'].includes(row.type)) && (
                    <S.GridItem key={row.label}>
                      <S.Label>{row.label}</S.Label>
                      <S.Value>{row.value}</S.Value>
                    </S.GridItem>
                  )}
                </React.Fragment>
              ))}
            </S.Grid>

            {formQuestions.data && (
              <S.FormAnswers>
                <S.FormAnswersHeader>
                  <S.FormAnswersTitle>Frågeformulär</S.FormAnswersTitle>

                  <S.FormAnswersDescription>
                    Svarsalternativ:
                    <S.FormAnswersTypes>
                      {treatmentFormQuestionMap.join(', ')}
                    </S.FormAnswersTypes>
                  </S.FormAnswersDescription>
                </S.FormAnswersHeader>

                {formQuestions.data.questions.map((item, i) => (
                  <S.FormItem key={item.question}>
                    <S.FormItemLabel>Fråga: {++i} / 12</S.FormItemLabel>
                    <S.FormItemQuestion>{item.question}</S.FormItemQuestion>
                    <S.FormItemAnswer>
                      Svar: {treatmentFormQuestionMap[item.answer]}
                    </S.FormItemAnswer>
                  </S.FormItem>
                ))}
              </S.FormAnswers>
            )}
          </S.Content>

          <S.Footer>
            <Modal.DismissButton onClick={() => props.setCancelModalOpen(true)}>
              <S.Abort disabled={current > end}>Avboka</S.Abort>
            </Modal.DismissButton>
            {!editMode && (
              <ButtonSecondary onClick={() => doEdit()}>Edit</ButtonSecondary>
            )}
            {editMode && (
              <ButtonPrimary onClick={() => doSave()}>Save</ButtonPrimary>
            )}
            <StartMeetingButton
              appointment={props.appointment}
              alwaysShow={true}
            />
          </S.Footer>
        </S.AppointmentInfoModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default AppointmentInfoModal
