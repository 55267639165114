import styled from 'styled-components'
import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  H3,
  TextArea,
} from '@bonliva-ui/web'
import { Link as ReactLink } from 'react-router-dom'
import { Icon } from '@bonliva-traits/web-icons'

export const DashboardTodaysMeetings = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  width: 100%;
  flex: 1;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  padding-bottom: ${({ theme }) => theme.margin['3xs']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const Title = styled(H3)`
  font-weight: 500;
`

export const SuccessModalTitle = styled(H3)`
  font-weight: 500;
  text-align: center;
`

export const TitleNumber = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darker};
`

export const ChevronRight = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.primary};
  width: 12px;
  height: 12px;
  transition: 0.2s ease-in-out;

  @-moz-document url-prefix() {
    margin-bottom: 2px;
  }
`

export const Link = styled(ReactLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 400;
  gap: ${({ theme }) => theme.margin['4xs']};

  ${ChevronRight} {
    transform: translate(0px, 0);
  }

  &:hover {
    ${ChevronRight} {
      transform: translate(4px, 0);
    }
  }
`

export const MeetingsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const ListItem = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
  flex-wrap: wrap;
  cursor: pointer;
`

export const ListItemPatientName = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
`

export const ListItemAppointmentCause = styled(BodySmall)``

export const ListItemAppointmentTime = styled(BodyRegular)``

export const NoMeetingsMessage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darker};
  font-weight: 400;
  text-align: center;
`

export const ShowModal = styled(ButtonPrimary)`
  margin-left: auto;
`

export const LoadingWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin['4xs']};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Label = styled(BodyRegular)``

export const CommentField = styled(TextArea)`
  resize: vertical;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const InfoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  margin: 0 auto;
`

export const Info = styled(BodyRegular)`
  display: flex;
  color: ${({ theme }) => theme.colors.web.dark};
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const Bold = styled(BodyRegular)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darkest};
`
